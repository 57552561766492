const TableSkeleton = () => {
  return (
    <table className="w-full border-separate border-spacing-y-4 animate-pulse">
      <thead className="bg-gray-200">
        <tr>
          <th scope="col" className="border border-gray-200 py-3.5" />
          <th scope="col" className="border border-gray-200 py-3.5" />
          <th scope="col" className="border border-gray-200 py-3.5" />
        </tr>
      </thead>
      <tbody className="bg-white">
        <tr className="bg-gray-200">
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
        </tr>
        <tr className=" bg-gray-200">
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
        </tr>
        <tr className=" bg-gray-200">
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
        </tr>
        <tr className=" bg-gray-200">
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
          <td className="border border-gray-200 py-3.5" />
        </tr>
      </tbody>
    </table>
  );
};

export default TableSkeleton;
